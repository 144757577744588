@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@500;600;800&display=swap');

.logo {
    font-family: 'Roboto Serif', sans-serif !important;
    font-weight: 800;
    font-size: 32px;
    float: right;
    position: relative;
}

.header {
    display: flex;
    place-content: space-between;
    align-items: center;
    width: 100vw;

    color: black;

    padding: 32px 8px;
    height: 50px;

    border-bottom: 1px solid grey;

    button {
        width: 100px;
        height: 50px;
    }
    
    .button-section {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title-custom {
        color: red;
        font-size: 24px;
        text-align: center;
        margin-bottom: -12px;
    }
}

button {
    border-radius: 5px;
    background-color: white;
    padding: 4px;
    font-size: 14px;
    border: 1px solid grey;
    width: 100%;
    max-width: 200px;
    max-height: 100px;
    cursor: pointer;
}

button:hover {
    background-color: lightgrey;
}

button:disabled {
    background-color: lightgrey;
    border: none;
    cursor: default;
}

.grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // min-height: calc(100vh);
    // min-height: -webkit-fill-available;
    // min-height: 100vh;
    height: calc(100vh - 200px);
    min-height: 610px;
    place-content: space-around;

    .grid-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        width: 100%;
        // height: 100%;
        min-height: 410px;

        .grid-row {
            display: flex;
            flex-direction: row;
            color: black;
            font-weight: bold;

            .grid-square {
                display: flex;
                align-items: center;
                justify-content: center;

                background-color: lightgrey;
                width: 50px;
                height: 50px;
                border-radius: 5px;
                margin: 8px;
            }

            .green {
                background-color: green;
                color: white;
            }

            .yellow {
                background-color: yellow;
            }

            .new {
                background-color: white;
                border: 1px solid lightgrey;
            }
        }
    }
}

.keyboard-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 500px;
    min-width: 375px;
    width: 100vw;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    .keyboard {
        display: flex;
        flex-direction: column;
        align-items: center;

        .row {
            margin-bottom: 4px;
            display: flex;
            align-self: stretch;
            margin: 2px 12px;
            min-width: 38px;

            .key {
                padding: 8px;
                border: none;
                border-radius: 5px;
                background-color: lightgrey;
                font-size: 18px;
                // font-weight: bold;
                // width: 38px;
                height: 38px;
                margin: 1px;
                flex: 1;
                cursor: pointer;
            }

            .key:hover {
                background-color: grey;
                color: white;
            }

            .grey {
                background-color: #eee;
                color: #ccc;
            }

            .green {
                background-color: green;
                color: white;
            }

            .yellow {
                background-color: yellow;
            }

            .large {
                width: fit-content;
            }

            .disabled {
                background-color: #eee;
                color: #ccc;
                cursor: default;
            }

            .disabled:hover {
                background-color: #eee;
                color: #ccc;
            }
        }
    }
}

.physics {
    margin-top: auto;
    border: 1px solid grey;
    border-radius: 10px;
    width: 375px;
    height: 375px;
    position: absolute;
    overflow: hidden;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.animate-header {
    animation: wobble 10s infinite;
    width: fit-content;
}

@keyframes wobble {
    0% { transform: rotate(-3deg); }
    50% { transform: rotate(5deg); }
    100% { transform: rotate(-3deg); }
}

@keyframes modal-show {
    0% { opacity: 0%; transform: scale(0.8); display: flex; }
    50% { opacity: 100%; transform: scale(1.025); }
    100% { opacity: 100%; transform: scale(1); }
}
@keyframes modal-hide {
    0% { opacity: 100%; transform: scale(1); }
    100% { opacity: 0%; transform: scale(.8); display: none;}
}

.modal-hide {
    animation-timing-function: ease-in-out !important;
    animation: modal-hide .5s !important;
}

.modal-wrapper {
    animation-timing-function: ease-in-out;
    animation: modal-show .5s;
    position: fixed;
    z-index: 1000000;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .modal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw - 20px);
        max-width: 500px;
        height: fit-content;
        background-color: white;
        border-radius: 10px;
        box-shadow: 5px 5px 20px rgba(0,0,0,.3);
        padding: 20px;
        
        .modal-title {
            font-family: 'Roboto Serif', sans-serif;
            font-weight: 400;
            font-size: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .modal-body {
            padding: 32px 0px;
            text-align: center;
            width: 100%;
        }

        .modal-options-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            place-content: space-around;
            margin-top: auto;
            border-top: 1pt dashed grey;
            padding-top: 8px;

            .modal-option {
                padding: 8px 12px;
                background-color: white;
                border-radius: 10px;
                font-size: 18px;
                min-width: 10px;
                text-align: center;
                cursor: pointer;
            }
            .modal-option:hover {
                background-color: #ccc;
            }
        }
    
    }
}

.tutorial-block {
    width: 18px;
    height: 18px;
    border: 1px solid black;
}

.guess-indicator {
    display: flex;
    // flex-direction: row;
    // width: 150px;
    font-size: 96px;
    font-weight: bold;
    align-items: flex-start;
    justify-content: center;
    // height: fit-content;
    z-index: 100;
    // margin-top: auto;
    // height: 100%;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    div {
        width: 14px;
        height: 14px;

        margin: 8px;
        border: 1px solid white;
        border-radius: 4px;
    }

    .remain {
        background-color: #eee;
    }

    .locked {
        background-color: #ccc;
    }
}

.footer {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    // position: absolute;
    // bottom: 0px;
    // margin-top: auto;
    // padding-bottom: 8px;
}

@keyframes shake {
    0% {
        transform: rotate(-1deg) scale(.99);
    }
    100% {
        transform: rotate(1deg) scale(1.01);
    }
}

@keyframes rumble {
    0% { transform: translate(-2px, 2px); }
    10% { transform: translate(1px, 3px); }
    20% { transform: translate(-1px, 2px); }
    30% { transform: translate(2px, 1px); }
    40% { transform: translate(-1px, 3px); }
    50% { transform: translate(3px, 1px); }
    60% { transform: translate(-1px, 2px); }
    70% { transform: translate(2px, 4px); }
    80% { transform: translate(-3px, 2px); }
    90% { transform: translate(2px, 1px); }
    100% { transform: translate(-1px, 3px); }
}

.error-message {
    color: red;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Roboto Serif';
    position: relative;
    top: 240px;
    // bottom: 90px;
    height: 0px;

    animation: shake .5s infinite alternate;
}
// .animate-header {
//     animation: wobble 10s infinite;
//     width: fit-content;
// }

.settings-modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    width: 100%;

    .spacer {
        width: 30px;
        // height: 18px;
    }

    label {
        width: 100%;
        display: flex;
        align-items: center;
    }

    input {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .warning {
        color: red;
        font-weight: bold;
        background-color: yellow;
        border-radius: 10px;
        animation: rumble .5s infinite alternate;
        width: fit-content;
        padding-right: 8px;
    }
}

.modal-shadow {
    width: 200%;
    height: 200%;
    background-color: rgba(0,0,0,.4);
    position: absolute;
    z-index: -10000;
}

.middle-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}