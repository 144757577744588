* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }

body {
  line-height: 30px;
  color: #767676;
  margin: 0px;
  height: 100vh;
    
  font-family: 'Mulish';
}

#root {
  width: 100%;
  height: 100vh;
}